import { createSlice } from '@reduxjs/toolkit'
import { RootState } from './store'
import { Market } from '@/lib/__generated/graphql.types';

interface LocationContextState {
  marketCode: string;
  localeCode: string;
  previewActive: boolean;
  validMarket: Market | undefined;
}

const initialState: LocationContextState = {
  marketCode: 'hk',
  localeCode: 'en',
  previewActive: false,
  validMarket: undefined
}

const locationContextSlice = createSlice({
  name: 'locationContext',
  initialState,
  reducers: {
    setMarket: (state, action) => {
      state.marketCode = action.payload
    },
    setLocale: (state, action) => {
      state.localeCode = action.payload
    },
    setPreviewActive: (state, action) => {
      state.previewActive = action.payload
    },
    setValidMarket: (state, action) => {
        state.validMarket = action.payload
    }
  }
})

export const { setMarket, setLocale, setPreviewActive, setValidMarket } = locationContextSlice.actions

export const selectMarketCode = (state: RootState) => state.locationContext.marketCode
export const selectLocaleCode = (state: RootState) => state.locationContext.localeCode
export const selectPreviewActive = (state: RootState) => state.locationContext.previewActive
export const selectValidMarket = (state: RootState) => state.locationContext.validMarket

export default locationContextSlice.reducer