import { configureStore } from '@reduxjs/toolkit'
import repReducer from './repSlice'
import locationContextReducer from './locationContextSlice'

export const store = configureStore({
	reducer: {
		locationContext: locationContextReducer,
		rep: repReducer,
	},
})

//https://redux-toolkit.js.org/usage/nextjs

// Infer the type of makeStore
export type AppStore = typeof store
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

export default store
