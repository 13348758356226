import { Suspense, lazy } from 'react'
import { Route, Routes } from 'react-router-dom'
import './App.css'
import { Provider } from 'react-redux'
import { store } from './state/store.ts'
import { HelmetProvider } from 'react-helmet-async'
import { gsap } from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger)

const NotFoundPage = lazy(() => import('./components/shared/NotFoundPage'))
const Page = lazy(() => import('./components/shared/Page'))
const Leadership = lazy(() => import('./components/shared/Leadership'))
const Event = lazy(() => import('./components/shared/Event'))
const Podcast = lazy(() => import('./components/shared/Podcast'))
const BlogPost = lazy(() => import('./components/shared/BlogPost'))
const helmetContext = {};

function App() {
  return (
    <HelmetProvider context={helmetContext}>
      <Provider store={store}>
        <Suspense fallback={<div>Loading...</div>}>
          <Routes>
            <Route path=":market/:locale/leadership/:slug" element={<Leadership />} />
            <Route path=":market/:locale/podcast/:slug" element={<Podcast />} />
            <Route path=":market/:locale/events/:slug" element={<Event />} />
            <Route path=":market/:locale/pulse/:slug" element={<BlogPost />} />
            <Route path=":market/:locale/*" element={<Page />} />
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </Suspense>
      </Provider>
    </HelmetProvider>
  )
}

export default App
