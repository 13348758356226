// https://redux-toolkit.js.org/usage/nextjs
'use client'
import { useRef } from 'react'
import { Provider } from 'react-redux'
import { store, AppStore } from './store'
import { setMarket, setLocale, setPreviewActive, setValidMarket } from './locationContextSlice'
import { setRepDID } from './repSlice'
import { Market } from '@/lib/__generated/graphql.types'

export default function StoreProvider({
	market,
	locale,
	previewActive,
	validMarket,
	children,
	repDID,
}: {
	market: string | null
	locale: string | null
	previewActive: boolean
	validMarket: Market | undefined
	children: React.ReactNode
	repDID: string | null
}) {
	const storeRef = useRef<AppStore>()
	if (!storeRef.current) {
		// Create the store instance the first time this renders
		storeRef.current = store
		storeRef.current.dispatch(setMarket(market) || 'hk')
		storeRef.current.dispatch(setLocale(locale) || 'en')
		storeRef.current.dispatch(setPreviewActive(previewActive) || false)
		storeRef.current.dispatch(setValidMarket(validMarket) || undefined)
		storeRef.current.dispatch(setRepDID(repDID) || null)
	}

	return <Provider store={storeRef.current}>{children}</Provider>
}
