import './index.css'
import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import { BrowserRouter } from 'react-router-dom'
import { HydrationBoundary, QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ContentfulLivePreviewProvider } from '@contentful/live-preview/react'
import StoreProvider from './state/StoreProvider'
import { getRepDID } from './hooks/useRepDomain'

const dehydratedState = window.__REACT_QUERY_STATE__ || {}
const marketLocaleData = window.__MARKET_LOCALE__ || {
	market: 'hk',
	locale: 'en',
	previewActive: false,
	validMarket: undefined,
}

const { market, locale, previewActive, validMarket } = marketLocaleData

function ContextProviders({ children }: { children: React.ReactNode }) {
	// https://tanstack.com/query/latest/docs/framework/react/guides/ssr#initial-setup
	const [queryClient] = React.useState(
		() =>
			new QueryClient({
				defaultOptions: {
					queries: {
						// With SSR, we usually want to set some default staleTime
						// above 0 to avoid refetching immediately on the client
						staleTime: 60 * 1000,
					},
				},
			})
	)

	const repDID = getRepDID()

	return (
		<QueryClientProvider client={queryClient}>
			<HydrationBoundary state={dehydratedState}>
				<StoreProvider
					market={market}
					locale={locale}
					previewActive={previewActive}
					repDID={repDID}
					validMarket={validMarket}
				>
					<ContentfulLivePreviewProvider
						locale={locale || 'en'}
						enableInspectorMode={previewActive}
						enableLiveUpdates={previewActive}
					>
						{children}
					</ContentfulLivePreviewProvider>
				</StoreProvider>
			</HydrationBoundary>
		</QueryClientProvider>
	)
}

ReactDOM.hydrateRoot(
	document.getElementById('root') as HTMLElement,
	<React.StrictMode>
		<BrowserRouter>
			<ContextProviders>
				<App />
			</ContextProviders>
		</BrowserRouter>
	</React.StrictMode>
)
