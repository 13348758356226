export function getRepDID() {
	//client
	return getRepDIDByUrl(window?.location?.hostname || '')
}

export function getRepDIDByUrl(url: string) {
	//server
	const hostArray = url.split('.')
	const repDID = hostArray[0]
	if (
		repDID.includes('localhost') ||
		repDID === '' ||
		repDID === 'corporate-site-v3' ||
		repDID === 'newulife' ||
		repDID === 'www'
	) {
		return null
	}
	return repDID
}
