import { createSlice } from '@reduxjs/toolkit'
import { RootState } from './store'

interface RepState {
	repDID: string | null
}

const initialState: RepState = {
	repDID: null,
}

const repSlice = createSlice({
	name: 'repDID',
	initialState,
	reducers: {
		setRepDID: (state, action) => {
			state.repDID = action.payload
		},
	},
})

export const { setRepDID } = repSlice.actions

export const selectRepDID = (state: RootState) => state.rep.repDID

export default repSlice.reducer
